@mixin fadeIn {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fadeOut {
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@mixin wave {
  @keyframes wave {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

@mixin slide {
  @keyframes slide {
    0% {
      left: 0;
    }
    25% {
      left: calc(100% - 20%);
    }
    50% {
      left: 0;
    }
    75% {
      left: calc(100% - 20%);
    }
    100% {
      left: 0;
    }

  }
}

@mixin ellipsis-dot {
  @keyframes ellipsis-dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@mixin jump {
  @keyframes jump {
    0% {
      bottom: 0;
    }
    20% {
      bottom: 7px;
    }
    40% {
      bottom: 0;
    }
  }
}

@mixin change {
  @keyframes change {
    25% {
      clip-path: polygon(50% 50%, 0 0, 0 100%, 0 100%, 0 100%, 0 100%);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 0 100%, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 0 100%, 100% 100%, 100% 0, 100% 0);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 0 100%, 100% 100%, 100% 0, 0% 0%);
    }
  }
}

@mixin lds-ripple {
  @keyframes lds-ripple {
    0% {
      top: 26px;
      left: 26px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: 52px;
      height: 52px;
      opacity: 0;
    }
  }
}

@mixin shineSlide {
  @keyframes shineSlide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

@mixin dash {
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}

@mixin rotate {
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

@mixin ellipsisDot {
  @keyframes ellipsis-dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}


@mixin showHide {
  @keyframes showHide {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}



