@use "positioning";

@mixin hover-alpha {
  &:hover {
    cursor: pointer;
    background: var(--grey-sur);
  }
}

@mixin noTouch {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


@mixin canTouch {
  -webkit-touch-callout: default !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
