@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";

@mixin init {
  :global {
    .pcr-app {
      border-radius: 12px !important;
      box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 20px 0 rgb(0 0 0 / 20%) !important;
      transition: 0s !important;
      z-index: 99999999 !important;


      .pcr-palette {
        border-radius: 8px !important;

        &:before {
          border-radius: 8px !important;
        }
      }

      .pcr-result {
        @include texts.font("semi-bold");
        border-radius: 8px !important;

        &:focus {
          @include outline.alpha(2);
        }
      }

      .pcr-swatches {
        button {
          border-radius: 100% !important;

          &::after {
            border-radius: 100% !important;
          }

          &::before {
            background: unset !important;
          }
        }
      }
    }

    .apexcharts-legend {
      left: -9px !important;
    }

    .apexcharts-tooltip {
      @include boxs.sur;
      border: unset !important;
      background: var(--white) !important;
      border-radius: 12px !important;
      padding: 8px !important;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 0 20px 0 rgba(0, 0, 0, 0.2) !important;

      .tooltip-content {
        .side {
          .value {
            font-size: 14px;
            display: flex;
            border-bottom: 1px solid var(--grey-sur);
            padding: 6px 0;
            width: 100%;
            gap: 6px;

            .color {
              border-radius: 5px;
              width: 12px;
              height: 12px;
              margin-top: 3px;
            }
          }

          .name {

          }

          .data {
            margin-left: auto;
            font-size: 15px;

            @include texts.font("semi-bold");
          }
        }

        .date {
          color: var(--black);
          font-size: 17px;

          @include texts.font("semi-bold");
        }
      }
    }

    .apexcharts-xaxistooltip {
      padding: 10px 16px !important;
      background: var(--white) !important;
      box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 20px 0 rgb(0 0 0 / 20%) !important;
      border: unset !important;
      border-radius: 10px !important;

      &:before {
        border-bottom-color: var(--grey-sur) !important;
      }

      &:after {
        border-bottom-color: var(--white) !important;
      }

      .apexcharts-xaxistooltip-text {
        font-size: 14px !important;
      }
    }

    .blot-formatter__overlay {
      border: 2px dashed rgb(55 159 255) !important;
      overflow: hidden !important;

      .ql-stroke {
        stroke: var(--black);
        stroke-width: 2px;
      }

      .blot-formatter__toolbar {
        box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 20px 0 rgb(0 0 0 / 20%);
        margin: auto;
        height: 24px !important;
        width: 72px !important;
        min-width: unset !important;
        overflow: hidden;
        border-radius: 5px;
        top: 0 !important;


        .blot-formatter__toolbar-button {
          border: unset !important;
          filter: unset !important;

          &.is-selected {

            svg {
              background: var(--grey-sur) !important;
            }
          }

          svg {
            border: unset !important;
          }
        }
      }


      .blot-formatter__resize-handle {
        background: rgb(55 159 255) !important;
        opacity: 1 !important;
        border: unset !important;
        border-radius: 2px !important;
        height: 8px !important;
        width: 8px !important;

        &[data-position="top-right"] {
          right: 1px !important;
          top: 1px !important;
        }

        &[data-position="top-left"] {
          left: 1px !important;
          top: 1px !important;
        }

        &[data-position="bottom-right"] {
          right: 1px !important;
          bottom: 1px !important;
        }

        &[data-position="bottom-left"] {
          left: 1px !important;
          bottom: 1px !important;
        }

      }
    }
  }
}