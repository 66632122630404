@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "boxs";

@mixin basic {
  cursor: pointer;
  color: var(--white);
  transition: 0.1s;
  border: none;
}

@mixin blue {
  @include basic;
  color: var(--blue-dark);

  &:hover {
    background: var(--blue-hover);
  }
}

@mixin blue-dark {
  background: var(--blue-dark);
  color: var(--white);

  @include basic;
  @include positioning.flex-globally;

  &:hover {
    background: var(--blue-punk);
  }
}

@mixin red {
  background: var(--red-real);
  @include basic;

  &:hover {
    background: var(--red-real-hover);
  }
}

@mixin red-alpha {
  color: var(--red);
  background: var(--red-alpha);
  @include basic;

  &:hover {
    background: var(--red-hover);
  }
}

@mixin green {
  background: var(--green);
  color: var(--white);
  @include basic;

  &:hover {
    background: var(--green-flash-hover);
  }
}

@mixin grey {
  background: var(--grey-sur);
  color: var(--black) !important;
  @include basic;

  &:hover {
    background: var(--grey-sur-hover);
  }
}

@mixin white {
  border: var(--grey-sur) 2px solid;
  background: var(--white);
  color: var(--black);
  @include basic;
}

@mixin round {
  cursor: pointer;
  border-radius: 100%;
  transition: 0.1s;

  @include effects.hover-alpha;

  &:active {
    outline: 0;
    transition: 0s;
    box-shadow: var(--blue-selection-shadow) 0 0 0 4px;
  }
}

@mixin round-alpha {
  @include round;
  @include effects.hover-alpha;
}

@mixin switch {
  display: inline-block;
  height: 22px;
  width: 42px;
  position: relative;

  & input {
    display: none;

    &:checked + .slider {
      background-color: var(--green-hover) !important;

      &:before {
        transform: translateX(20px);
      }
    }
  }

  .slider {
    background-color: var(--red-real);
    bottom: 0;
    cursor: pointer;
    left: 0;
    border-radius: 50px;
    right: 0;
    top: 0;
    transition: .4s;
    position: absolute;

    &:before {
      border-radius: 34px;
      background-color: var(--white);
      bottom: 4px;
      content: "";
      height: 14px;
      left: 4px;
      transition: 0.4s;
      width: 14px;
      position: absolute;
      box-shadow: 0 0 0 2px #0000001f;
    }
  }
}

@mixin checkbox {
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  height: 22px;
  width: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  & input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    position: absolute;

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    top: 0;
    left: 0;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: var(--grey-sur);
    cursor: pointer;
    position: absolute;

    &:after {
      content: "";
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid black;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
    }
  }
}

@mixin dropdown {
  display: inline-block;
  position: relative;

  .button {
    &:after {
      content: '';
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      position: absolute;
    }

    &:hover {
      background-color: var(--grey-sur);
    }
  }

  .input {
    display: none;

    &:checked + .menu, &:checked + ul {
      display: block;
      z-index: 9999999;
    }

    &:checked {
      display: block;
      z-index: 999999;
      left: 0;
      top: 0;
      height: 100%;
      opacity: 0;
      position: fixed;
      width: 100%;
    }

    & + .menu, & + ul {
      display: none;
    }
  }

  .menu, ul {
    @include boxs.sur;
    z-index: 9999999;
    top: 100%;
    border-radius: 15px;
    padding: 5px;
    overflow: hidden;
    margin-top: 5px;
    list-style-type: none;
    position: absolute;

    li {
      border-radius: 12px;
      padding: 10px 20px;
      cursor: pointer;
      white-space: nowrap;

      @include effects.hover-alpha;

      a {
        display: block;
        margin: -10px -20px;
        padding: 10px 20px;
      }
    }
  }
}