@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/inputs";

:global {
  body {
    @include texts.font("medium");
    margin: 0;
    padding: 0;
  }

  main {
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
  }

  input {
    @include inputs.border;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }
}
