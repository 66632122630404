@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

@mixin tagsDocs {
  :global {
    blockquote {
      &.ql-line-q {
        margin-top: 0 !important;
      }
    }
  }

  blockquote {
    margin: 18px 0px 0px 0.8ex !important;
    line-height: 22px;
  }

  p {
    margin-top: 16px;
    line-height: 22px;
  }

  li {
    line-height: 22px;
  }

  ol, ul {
    margin-top: 16px;
  }
}

@mixin tags {

  img {
    cursor: pointer;
    border-radius: 10px;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }

  ul, ol {
    margin: 0;
    padding-inline-start: 14px;
    list-style-type: disc;
  }

  strong, b {
    @include texts.font("bold");
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    @include texts.font("bold");
  }

  @media screen and (max-width: 480px) {

    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 16px;
    }

    img {
      max-width: 100%;
      height: unset;
    }
  }
}