@mixin clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin center-horizontally {
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-globally {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-horizontally {
  display: flex;
  justify-content: center;
}

@mixin flex-vertically {
  display: flex;
  align-items: center;
}

@mixin flex-globally {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin screen-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

@mixin webResponsive {
  width: calc(100% - 40px);
  max-width: 1150px;
}