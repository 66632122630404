@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/keyframes";
@use "sass:math";

@mixin spinner-basic {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: relative;

  div {
    @include keyframes.lds-ripple;
    border: 4px solid black;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    position: absolute;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
}

@mixin spinner-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 40px;
  height: 40px;
  position: absolute;

  @include keyframes.rotate;
  @include keyframes.dash;

  .path {
    stroke-linecap: round !important;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke: var(--black);
    stroke-width: 3px;
  }
}

@mixin spinner-stopwatch {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  pointer-events: none;
  margin-bottom: 10px;
  border: 6px solid #e8e8e8;
}

@mixin spinner-stopwatch-circle {
  @include keyframes.change;

  left: -6px;
  top: -6px;
  width: 100%;
  height: 100%;
  border: 5px solid #000;
  border-radius: 50%;
  transform: rotate(45deg);
  clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  animation: change 5s linear forwards;
  position: relative;
}

@mixin spinner-progress {
  bottom: 0;
  z-index: 999;

  svg {
    height: 45px;
    width: 45px;

    .progress {
      fill: transparent;
      stroke-width: 7;
      stroke-linecap: round;
      stroke: var(--black);
      stroke-dasharray: 314.16;
      stroke-dashoffset: 314.16;
      transition: stroke-dashoffset 0.5s ease;
    }

    .text {
      fill: var(--black);
      font: 35px "Montserrat Bold", Arial, sans-serif;
      -moz-transform: translateY(0.25em);
      -ms-transform: translateY(0.25em);
      -webkit-transform: translateY(0.25em);
      transform: translateY(0.25em);

      .number {
        font-size: 0.6em;
        line-height: 1;
        text-anchor: middle;
        -moz-transform: translateY(-1px);
        -ms-transform: translateY(-1px);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
      }
    }
  }
}

@mixin sonar {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 9999px;
  background: blue;

  .wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background: var(--red);
    opacity: 0;
    pointer-events: none;
    animation: sonarWave 2s linear infinite;
  }

  @keyframes sonarWave {
    from {
      opacity: 0.4;
    }
    to {
      transform: scale(3);
      opacity: 0;
    }
  }
}

@mixin shine {
  @include keyframes.shineSlide;
  overflow: hidden;

  &:after {
    content: '';
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    animation: shineSlide 1s infinite;
    position: absolute;
    display: block;
    left: 0;
    background: linear-gradient(90deg, transparent, var(--white), transparent);
  }
}

@mixin shineBlack {
  @include keyframes.shineSlide;
  overflow: hidden;

  &:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    animation: shineSlide 1.2s infinite;
    background: -moz-linear-gradient(left, rgb(60 60 60 / 0%) 0%, rgb(0 0 0 / 10%) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(60 60 60 / 0%)), color-stop(50%, rgb(0 0 0 / 10%)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgb(60 60 60 / 0%) 0%, rgb(0 0 0 / 10%) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgb(60 60 60 / 0%) 0%, rgb(0 0 0 / 10%) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgb(60 60 60 / 0%) 0%, rgb(0 0 0 / 10%) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* IE10+ */
    background: linear-gradient(to right, rgb(60 60 60 / 0%) 0%, rgb(0 0 0 / 10%) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1); /* IE6-9 */
    position: absolute;
  }
}

@mixin shine-hover {
  flex: 0 0 auto;
  width: 280px;
  overflow: hidden;
  height: 195px;
  vertical-align: top;
  letter-spacing: normal;
  text-rendering: auto;
  position: relative;

  &:after {
    content: "";
    top: 0;
    display: block;
    left: 0;
    pointer-events: none;
    width: 0;
    border-radius: 5px;
    height: 100%;
    background-color: var(--black-light-alpha);
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    position: absolute;
  }

  &:hover:after {
    width: 100%;
    border-radius: 5px;
    pointer-events: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

@mixin dots-jumping {
  position: relative;

  span {
    @include keyframes.jump;
    display: inline-block;
    margin: 0 3.6px;
    animation: jump 1s infinite;
    position: relative;
    float: left;
  }

  @for $i from 1 through 3 {
    span:nth-child(#{$i}) {
      animation-delay: (math.div($i - 1, 10)) + s;
    }
  }
}

@mixin dots-span {
  @include keyframes.ellipsisDot;

  opacity: 0;
  animation: ellipsis-dot 1s infinite;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: math.div($i - 1, 10) + s;
    }
  }
}


@mixin show-hide {
  @include keyframes.showHide;
  animation: showHide 1s infinite;
}

@mixin fade-in {
  @include keyframes.fadeIn;
  animation: fadeIn 1s;
}

@mixin fade-out {
  @include keyframes.fadeOut;
  animation: fadeOut 1s;
}

@mixin ripple {
  transition: 0.8s;
  &:active {
    background: var(--grey-sur);
    transition: 0s;
  }
}